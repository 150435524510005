import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FC, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import ALink from 'components/atoms/ALink';
import MHeaderDropdown from 'components/molecules/MHeaderDropdown';

import MenuIcon from 'public/assets/svg/burger menu.svg';
import MLanguageSelector from 'components/molecules/MLanguageSelector';

interface DrawerProps {
  navLinks?: {
    title: string;
    path: string;
  }[];
  isLoggedIn: boolean;
}

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const SideDrawer: FC<DrawerProps> = ({ navLinks, isLoggedIn }) => {
  const [state, setState] = useState({ right: false });
  const { t } = useTranslation('common');

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const sideDrawerList = (anchor) => (
    <Box
      minWidth={231}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List component="nav" id="mobile-drawer">
        {navLinks.map(({ title, path }) => (
          <ALink isBlock href={path} key={title}>
            <ListItem button>
              <ListItemText primary={title} />
            </ListItem>
          </ALink>
        ))}
        {isLoggedIn ? (
          <>
            <ListItem button>
              <Box width="100%">
                <MHeaderDropdown isSidebar />
              </Box>
            </ListItem>
            <ListItem button>
              <Box mt={8} width="100%">
                <MLanguageSelector isOnSideBar />
              </Box>
            </ListItem>
          </>
        ) : (
          <div>
            <ListItem button>
              <Box mt={8} width="100%">
                <MLanguageSelector isOnSideBar />
              </Box>
            </ListItem>
            <ALink isBlock href="/login">
              <ListItem>
                <Button fullWidth variant="contained" className="c-primary-button c-primary-button--trans">
                  {t('action.login')}
                </Button>
              </ListItem>
            </ALink>
            <ALink isBlock href="/register">
              <ListItem>
                <Button fullWidth variant="contained" color="primary" className="c-primary-button">
                  {t('action.signUp')}
                </Button>
              </ListItem>
            </ALink>
          </div>
        )}
      </List>
    </Box>
  );

  return (
    <>
      <IconButton edge="end" aria-label="menu" onClick={toggleDrawer('right', true)}>
        <MenuIcon />
      </IconButton>

      <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
        {sideDrawerList('right')}
      </Drawer>
    </>
  );
};

export default SideDrawer;
