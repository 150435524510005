import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import { useSelector } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import useRouter from 'next/router';
import Image from 'next/image';

import ALink from 'components/atoms/ALink';
import MHeaderDropdown from 'components/molecules/MHeaderDropdown';
import MLanguageSelector from 'components/molecules/MLanguageSelector';
import { AppState, LoginData } from 'redux-saga/interfaces';
import { isIndonesianDomain } from 'utils/common';
import SideDrawer from './SideDrawer';

const Header = () => {
  const { t } = useTranslation('common');

  const navLinks = [
    { title: t('landingMenu.sampleCv'), path: '/sample-cv' },
    { title: t('landingMenu.cvBuilder'), path: '/cv-builder' },
    { title: t('landingMenu.pricing'), path: '/pricing' },
    { title: t('landingMenu.hire'), path: '/hire' },
    { title: t('landingMenu.blog'), path: `https://blog.urbancv.${isIndonesianDomain() ? 'id' : 'com'}` },
  ];
  const { token } = useSelector<AppState, LoginData>((state) => state.userInfo);
  const isLoggedIn = !!token;

  return (
    <Container>
      <AppBar position="static" id="app-bar">
        <Toolbar disableGutters className="u-justify-between m-header">
          <ALink href="/">
            <Image src="/assets/logo.png" alt="Urban CV" width={150} height={30} layout="fixed" className="u-pointer" />
          </ALink>
          <Hidden smDown>
            <List component="nav" id="header-nav">
              {navLinks.map(({ title, path }) => (
                <ALink href={path} key={title}>
                  <ListItem button>
                    <ListItemText primary={title} />
                  </ListItem>
                </ALink>
              ))}
            </List>
            {!isIndonesianDomain() && (
              <Box mr={isLoggedIn ? 4 : 0}>
                <MLanguageSelector />
              </Box>
            )}
            {isLoggedIn ? (
              <MHeaderDropdown />
            ) : (
              <Box>
                <ALink href="/login">
                  <Button variant="text" className="c-primary-button c-primary-button--trans">
                    {t('action.login')}
                  </Button>
                </ALink>
                <Button
                  variant="contained"
                  color="primary"
                  className="c-primary-button"
                  onClick={() => useRouter.push('/register')}
                >
                  {t('action.signUp')}
                </Button>
              </Box>
            )}
          </Hidden>
          <Hidden mdUp>
            <SideDrawer navLinks={navLinks} isLoggedIn={isLoggedIn} />
          </Hidden>
        </Toolbar>
      </AppBar>
    </Container>
  );
};

export default Header;
